function SideButtons(props) {
    return (
        <div className={"flex flex-col"}>
            {props.currentButtonType === "REVEAL" ? (
                <span className={"relative border border-gray-400 w-5 h-5 inline-flex items-center justify-center font-bold"}>1</span>
            ) : (
                <button className={"border-[3px] border-t-white leading-none text-sm inline-flex items-center justify-center border-l-white border-b-gray-400 border-r-gray-400 w-5 h-5 bg-gray-200"} onClick={() => {props.setButtonType("REVEAL")}}>1</button>
            )}
            {props.currentButtonType === "MARK" ? (
                <span className={"relative border border-gray-400 w-5 h-5 inline-flex items-center justify-center font-bold"}>⚑</span>
            ) : (
                <button className={"border-[3px] border-t-white leading-none text-sm inline-flex items-center justify-center border-l-white border-b-gray-400 border-r-gray-400 w-5 h-5 bg-gray-200"} onClick={() => {props.setButtonType("MARK")}}>⚑</button>
            )}

        </div>
    )
}

export default SideButtons;