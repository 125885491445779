import './App.css';
import Board from './Board';
import {useEffect, useState, useCallback} from "react";
import Counter from "./Counter";
import SideButtons from "./SideButtons";

function App() {
    const [size, setSize] = useState([9,9]);
    const [numMines, setNumMines] = useState(10);
    const [board, setBoard] = useState([]);
    const [started, setStarted] = useState(false);
    const [timer, setTimer] = useState(0);
    const [counter, setCounter] = useState(numMines);
    const [loser, setLoser] = useState(false);
    const [winner, setWinner] = useState(false);
    const [intervalID, setIntervalID] = useState(0);
    const [buttonType, setButtonType] = useState('REVEAL');

    const startTimer = () => {
        if (!intervalID) {
            setIntervalID(setInterval(() => {
                setTimer((prevTimer) => prevTimer + 1);
            }, 1000));
        }
    }

    const stopTimer = useCallback(() => {
        if (intervalID) {
            clearInterval(intervalID);
            setIntervalID(0);
        }
    },[intervalID])

    useEffect(() => {
        if (loser || winner) {
            stopTimer();
        }
    },[loser,winner, stopTimer])

    const generateBoard = (sizeX, sizeY) => {
        let board = [];

        for (let i = 0; i < sizeX; i++) {
            let temp = [];

            for (let j = 0; j < sizeY; j++) {
                temp.push({
                    isMine: false,
                    isNumber: false,
                    isBlank: true,
                    isMarked: false,
                    isRevealed: false,
                    foundMine: false,
                    number: 0
                })
            }

            board.push(temp);
        }

        return board;
    }

    const generateMines = (board, _numMines) => {
        const sizeX = board.length;
        const sizeY = board[0].length;

        for (let i = 0; i < _numMines; i++) {
            while (true) {
                const x = Math.floor(Math.random() * (sizeX));
                const y = Math.floor(Math.random() * (sizeY));

                if (board[x][y].isMine) {
                    continue;
                }

                board[x][y].isMine = true;
                board[x][y].isBlank = false;

                break;
            }
        }
    }

    const generateNumbers = (board) => {
        const min = 0;
        const maxI = board.length - 1;
        let maxJ;

        for (let i = 0; i < board.length; i++) {
            let row = board[i];
            maxJ = row.length - 1;

            for (let j = 0; j < row.length; j++) {
                let square = row[j];

                if (!square.isMine) {
                    continue;
                }

                if (i - 1 >= min && j - 1 >= min) {
                    increaseSquareNumber(board,i-1,j-1);
                }

                if (i - 1 >= min) {
                    increaseSquareNumber(board,i-1,j);
                }

                if (i - 1 >= min && j + 1 <= maxJ) {
                    increaseSquareNumber(board,i-1,j+1);
                }

                if (j - 1 >= min) {
                    increaseSquareNumber(board,i,j-1);
                }

                if (j + 1 <= maxJ) {
                    increaseSquareNumber(board,i,j+1);
                }

                if (i + 1 <= maxI && j - 1 >= min) {
                    increaseSquareNumber(board,i+1,j-1);
                }

                if (i + 1 <= maxI) {
                    increaseSquareNumber(board,i+1,j);
                }

                if (i + 1 <= maxI && j + 1 <= maxJ) {
                    increaseSquareNumber(board,i+1,j+1);
                }
            }
        }
    }

    const increaseSquareNumber = (board, i, j) => {
        if (!board[i][j].isMine) {
            board[i][j].isNumber = true;
            board[i][j].isBlank = false;
            board[i][j].number++;
        }
    }

    const generateGame = (sizeX = size[0], sizeY = size[1], _numMines = numMines) => {
        stopTimer();
        setStarted(false);
        setTimer(0);
        setCounter(_numMines);
        setWinner(false);
        setLoser(false);
        setSize([sizeX,sizeY]);
        setNumMines(_numMines);

        const temp = generateBoard(sizeX, sizeY);
        generateMines(temp, _numMines);
        generateNumbers(temp);

        setBoard(temp);
    }

    const handleRevealSquare = (x,y) => {
        const tempBoard = [...board];
        const tempSquare = tempBoard[x][y];

        if (tempSquare.isMarked) {
            return;
        }

        if (tempSquare.isMine) {
            tempSquare.foundMine = true;
            setBoard(tempBoard);
            busted();
            return;
        }

        if (tempSquare.isBlank) {
            revealNearbyBlankSquares(tempBoard,x,y);
        }

        tempSquare.isRevealed = true;
        setBoard(tempBoard);
    }

    const busted = () => {
        setLoser( true);
        revealAllMines();
    }

    const revealAllMines = () => {
        const tempBoard = [...board];

        for (let i = 0; i < tempBoard.length; i++) {
            let row = tempBoard[i];

            for (let j = 0; j < row.length; j++) {
                if (tempBoard[i][j].isMine) {
                    tempBoard[i][j].isRevealed = true;
                }
            }
        }

        setBoard(tempBoard);
    }

    const revealNearbyBlankSquares = (tempBoard, x, y) => {
        const min = 0;
        const maxX = tempBoard.length - 1;
        const maxY = tempBoard[0].length - 1;

        if (x - 1 >= min && y - 1 >= min) {
            revealBlankSquare(tempBoard,x-1, y-1);
        }

        if (x - 1 >= min) {
            revealBlankSquare(tempBoard,x-1, y);
        }

        if (x - 1 >= min && y + 1 <= maxY) {
            revealBlankSquare(tempBoard,x-1, y+1);
        }

        if (y - 1 >= min) {
            revealBlankSquare(tempBoard,x, y-1);
        }

        if (y + 1 <= maxY) {
            revealBlankSquare(tempBoard,x, y+1);
        }

        if (x + 1 <= maxX && y - 1 >= min) {
            revealBlankSquare(tempBoard,x+1, y-1);
        }

        if (x + 1 <= maxX) {
            revealBlankSquare(tempBoard,x+1, y);
        }

        if (x + 1 <= maxX && y + 1 <= maxY) {
            revealBlankSquare(tempBoard,x+1, y+1);
        }
    }

    const revealBlankSquare = (tempBoard,x,y) => {
        if (!tempBoard[x][y].isRevealed && !tempBoard[x][y].isMine && !tempBoard[x][y].isMarked) {
            tempBoard[x][y].isRevealed = true;

            if (tempBoard[x][y].isBlank) {
                revealNearbyBlankSquares(tempBoard,x,y);
            }
        }
    }

    const handleSquareClick = (event, x, y) => {
        if (winner || loser) {
            return;
        }

        if (event.button === 2 || buttonType === "MARK") {
            handleMarkSquare(x,y);
        } else {
            handleRevealSquare(x,y)
        }

        if (!started) {
            setStarted(true);
            startTimer();
        }

        if (!loser && checkWinner()) {
            setWinner(true);
            revealAllMines();
        }
    }

    const checkWinner = () => {
        let markedTotal = 0;

        for (let i = 0; i < board.length; i++) {
            let row = board[i];

            for (let j = 0; j < row.length; j++) {
                if (!board[i][j].isRevealed) {
                    markedTotal++;
                }
            }
        }

        return markedTotal === numMines;
    }

    const handleMarkSquare = (x,y) => {
        const tempBoard = [...board];
        const tempSquare = tempBoard[x][y];

        if (tempSquare.isRevealed) {
            return;
        }

        const tempCounter = tempSquare.isMarked ? counter + 1 : counter - 1;

        setCounter(tempCounter);

        tempSquare.isMarked = !tempSquare.isMarked;
        setBoard(tempBoard);
    }

    const handleSetButtonType = (type) => {
        setButtonType(type);
    };

    const handleSetDifficulty = (dificulty) => {
        switch (dificulty) {
            case '1':
                generateGame(9,9,10);
                break;
            case '2':
                generateGame(16,16,40);
                break;
            case '3':
                generateGame(16,30,99);
                break;
        }
    }

    useState(() => {
        generateGame(size[0],size[1],numMines);
    },[])

    return (
        <div className={"h-full flex flex-col justify-center items-center select-none"}>
            <div className={"flex space-x-4"}>
                <button className={"border-[3px] border-t-white leading-none inline-flex items-center justify-center border-l-white border-b-gray-400 border-r-gray-400 px-4 py-2 uppercase font-bold bg-gray-200 text-green-500"} onClick={() => {handleSetDifficulty('1')}}>Easy</button>
                <button className={"border-[3px] border-t-white leading-none inline-flex items-center justify-center border-l-white border-b-gray-400 border-r-gray-400 px-4 py-2 uppercase font-bold bg-gray-200 text-orange-500"} onClick={() => {handleSetDifficulty('2')}}>Medium</button>
                <button className={"border-[3px] border-t-white leading-none inline-flex items-center justify-center border-l-white border-b-gray-400 border-r-gray-400 px-4 py-2 uppercase font-bold bg-gray-200"} onClick={() => {handleSetDifficulty('3')}}>Hard</button>
            </div>
            <div className={"h-max flex mt-8"}>
                <div className={""}>
                    <SideButtons currentButtonType={buttonType} setButtonType={handleSetButtonType} />
                </div>
                <div className={"w-max border-4 border-b-gray-400 border-r-gray-400 border-t-white border-l-white bg-gray-200 p-2"}>
                    <Counter regenerate={generateGame} timer={timer} counter={counter} loser={loser} winner={winner} />
                    <Board board={board} squareClick={handleSquareClick} isLoser={loser}/>
                </div>
            </div>
        </div>
    );
}

export default App;
